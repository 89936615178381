import { useState, useEffect } from 'react';
import axios from "axios";
import { Container} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import '../theme/formStyle.css'

export default function NewTerms(){    

const query = 'https://api.roosterscow.com:9000/tcs/create';
  
  const [data, setData] = useState({
    title: "",
    description: "",
    summary: "",
  });

  const [file,setFile] = useState(null);

  function submit(e){
    e.preventDefault();

    const info = {
      title: data.title,
      description:data.description,
      summary: data.summary,
    }
    axios({
      method: 'post',
      url: query,
      data: info,
      headers: {
          'Contet-Type': 'application/json',
      },
    })
    .then((response) =>{
      window.location ="/dashboard/privacy-policy";
    })
    .catch((err)=>{
      console.info(err);
      console.info(info);
    })
  };

  function handle(e){
    const newdata={...data};
    newdata[e.target.name] = e.target.value;
    setData(newdata);
    console.info(newdata);
  };

  function handleFileSelect(event){
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

    return(              
        <>
          <Helmet>
            <title> New Privacy Policy | El Camino - Trucking </title>
          </Helmet>
          <Container>
            <div className="test">
                <h2>New T&C</h2>
                <form onSubmit={(e)=>submit(e)}>
                    <div className='marginTen'>
                      <span>T&C Title</span>
                        <input onChange={(e)=>handle(e)} value={data.title} name="title" placeholder="Title" type="text" className='formInput'/>
                    </div>
                    <div className='marginTen'>
                    <span>T&C Description</span>
                        <input onChange={(e)=>handle(e)} value={data.description} name="description" placeholder="Description" type="textarea" className='formInput'/>
                    </div>
                    <div className='marginTen'>
                    <span>T&C Summary</span>
                        <input onChange={(e)=>handle(e)} value={data.summary} name="summary" placeholder="Summary" type="text" className='formInput'/>
                    </div>
                    <div>
                        <input type="submit" value="Submit" className='submitBtn'/>
                    </div>
                </form>
            </div>
        </Container>
        </>
    )
}


