import { useState, useEffect } from 'react';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { Container} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import '../theme/formStyle.css'


export default function EditUser(props){
    const [searchParams, setSearchParams] = useSearchParams();
    
    const editQuery=`https://api.roosterscow.com:9000/user/findId/${searchParams.get("id")}`

    const [editInfo, setEditInfo] = useState({
		id: "",
		first_name: "",
		last_name: "",
		email: "",
        role: ""
	});
    
    useEffect(()=>{
        axios({
            method: 'get',
            url: editQuery,
            headers: {
                'Content-type' : 'application/json',
            },
        })
          .then(res=>{
            console.info(res.data)
            setEditInfo({
                id: res.data._id,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                email: res.data.email,
                role: res.data.role,
            });
          })
        },[]);

    const editedQuery = `https://api.roosterscow.com:9000/user/updateId/${editInfo.id}`;

	function editSubmit(e){
		e.preventDefault();
        const loginPayload = {
            role: editInfo.role,
        }
        axios({
            method: 'post',
            url: editedQuery,
            data: loginPayload,
            headers: {
                'Content-type' : 'application/json',
            },
        })
        .then(response => {
            window.location ="/dashboard/user";
        })
        .catch(err => alert(err.response.data));
    };

    function handleEdit(e){
        const newdata={...editInfo};
        newdata[e.target.name] = e.target.value;
        setEditInfo(newdata);
    };

    return(   
        <>           
            <Helmet>
                <title> Edit User | El Camino - Trucking </title>
            </Helmet>
            <Container>
                <div>
                    <p>Edit User Role</p>
                    <form onSubmit={(e)=>editSubmit(e)}>
                        <span>User First Name</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.first_name} name="first_name" type="text" className='formInput' readOnly />
                        </div>
                        <span>User First Name</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.last_name} name="last_name" type="text" className='formInput' readOnly />
                        </div>
                        <span>User Email</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.email} name="email" type="textarea" className='formInput' readOnly />
                        </div>
                        <span>User Role</span>
                        <div className='marginTen'>
                        <select onChange={(e)=>handleEdit(e)} name="role" className='formInput'>
                              <option>-- Choose one role --</option>
                              <option value="User">User</option>
                              <option value="Admin">Admin</option>
                            </select>
                        </div>
                        <input type="submit" value="Edit user" className='submitBtn'/>
                    </form>
                </div>
            </Container>
            </>
    )
}


