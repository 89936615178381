import { useState, useEffect } from 'react';
import axios from "axios";
import { Container} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import '../theme/formStyle.css'

export default function NewAds(){    

const query = 'https://api.roosterscow.com:9000/animal/add';
  
  const [data, setData] = useState({
    userId:localStorage.getItem('userId'),
    broughtFrom: "",
    name: "",
    breed: "",
    color: "",
    sex: "",
    price: "",
    date: new Date()
  });

  const [file,setFile] = useState(null);

  function submit(e){
    e.preventDefault();

    const info = {
      userId: data.userId,
      broughtFrom: data.broughtFrom,
      name: data.name,
      breed: data.breed,
      color: data.color,
      sex: data.sex,
      price: data.price,
      date: data.date
    }

  axios({
    method: 'post',
    url: query,
    data: info,
    headers: {
        'Contet-Type': 'application/json',
    },
  })
  .then((response) =>{
    window.location ="/dashboard/my-scan";
  })
  .catch((err)=>{
    console.info(err);
    console.info(info);
  })
  };

  function handle(e){
    const newdata={...data};
    newdata[e.target.name] = e.target.value;
    setData(newdata);
    console.info(newdata);
  };

  function handleFileSelect(event){
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

    return(              
        <>
          <Helmet>
            <title> New Tag | El Camino - Trucking </title>
          </Helmet>
            <Container>
                <div className="test">
                    <h2>New Tag</h2>
                    <form onSubmit={(e)=>submit(e)}>
                        <div className='marginTen'>
                          <span>Bought from</span>
                            <input onChange={(e)=>handle(e)} value={data.broughtFrom} name="broughtFrom" placeholder="Bought from" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Name/ID</span>
                            <input onChange={(e)=>handle(e)} value={data.name} name="name" placeholder="Name/ID" type="text"  className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Breed</span>
                            <input onChange={(e)=>handle(e)} value={data.breed} name="breed" placeholder="Breed" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Color</span>
                            <input onChange={(e)=>handle(e)} value={data.color} name="color" placeholder="Color" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Sex</span>
                            <input onChange={(e)=>handle(e)} value={data.sex} name="sex" placeholder="Sex" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Price</span>
                            <input onChange={(e)=>handle(e)} value={data.price} name="price" placeholder="Price" type="number" className='formInput'/>
                        </div>

                        <div>
                            <input type="submit" value="Submit" className='submitBtn'/>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    )
}


