import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/Register';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import EditTag from './components/EditTag';
import NewTag from './components/NewTag';
import EditUser from './components/EditUser';
import NewTerms from './components/NewTerms';
import EditTerms from './components/EditTerms';
import Homepage from './components/HomePage';
import Privacy from './pages/Privacy';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'my-scan', element: <ProductsPage /> },
        { path: 'privacy-policy', element: <Privacy /> },
        { path: 'new-tag', element: <NewTag /> },
        { path: 'edit-tag', element: <EditTag /> },
        { path: 'new-terms', element: <NewTerms /> },
        { path: 'edit-terms', element: <EditTerms /> },
        { path: 'edit-user', element: <EditUser /> },
       
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      children: [
        { path: '/', element: <Homepage />  },
        { path: '/privacy', element: <Privacy />  },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
