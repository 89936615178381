import { useState, useEffect } from 'react';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { Container} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import '../theme/formStyle.css'


export default function EditTerms(props){
    const [searchParams, setSearchParams] = useSearchParams();
    
    const editQuery=`https://api.roosterscow.com:9000/tcs/findId/${searchParams.get("id")}`;

    const [headerLoading, setHeaderLoading] = useState(false);
    const [editInfo, setEditInfo] = useState({
		id: "",
		title: "",
		description: "",
		summary: "",
	});

    useEffect(()=>{
        setHeaderLoading(true);
        axios({
            method: 'get',
            url: editQuery,
            headers: {
                'Content-Type': 'application/json',
            },
        })
          .then(res=>{
            setEditInfo({
                id: res.data._id,
                title: res.data.title,
                description: res.data.description,
                summary: res.data.summary,
            });
            setHeaderLoading(false);
          })
        },[]);

    const edited = `https://api.roosterscow.com:9000/tcs/updateId/${editInfo.id}`;

	function editSubmit(e){
		e.preventDefault();
        const jsonData = {
            title: editInfo.title,
            description: editInfo.description,
            summary: editInfo.summary,
        }
        
        axios({
            method: 'post',
            url: edited,
            data: jsonData,
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) =>{
            window.location ="/dashboard/privacy-policy";
        })
        .catch((err)=>{
            alert(err.response.data);
        });
    };

    function handleEdit(e){
        const newdata={...editInfo};
        newdata[e.target.name] = e.target.value;
        setEditInfo(newdata);
    };

    return(       
        <>       
            <Helmet>
                <title>New Privacy Policy | El Camino - Trucking </title>
            </Helmet>

            <Container>
                <div>
                    <p>Edit</p>
                    <form onSubmit={(e)=>editSubmit(e)}>
                        <span>Term title</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.title} name="title" type="text" className='formInput' required />
                        </div>
                        <span>Term summary</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.description} name="description" type="text" className='formInput' required />
                        </div>
                        <span>Term description</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.summary} name="summary" type="textarea" className='formInput' required />
                        </div>
                        <input type="submit" value="Edit policy" className='submitBtn'/>
                    </form>
                </div>
            </Container>
        </>
    )
}


