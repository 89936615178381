import { useState, useEffect } from 'react';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { Container} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import '../theme/formStyle.css'


export default function EditSport(props){
    const [searchParams, setSearchParams] = useSearchParams();
    
    const editQuery=`https://api.roosterscow.com:9000/animal/findId/${searchParams.get("id")}`

    const [editInfo, setEditInfo] = useState({
		userId:localStorage.getItem('userId'),
        id:"",
        broughtFrom: "",
        name: "",
        breed: "",
        color: "",
        sex: "",
        price: "",
        date: "",
	});
    
  

    useEffect(()=>{
        axios({
            method: 'get',
            url: editQuery,
            headers: {
                'Contet-Type': 'application/json',
            },
        })
          .then(res=>{
            console.info(res.data)
            setEditInfo({
                id: res.data._id,
                broughtFrom: res.data.broughtFrom,
                name: res.data.name,
                breed: res.data.breed,
                color: res.data.color,
                sex: res.data.sex,
                price: res.data.price,
                date: res.data.date
            });
          })
        },[]);

    const edited = `https://api.roosterscow.com:9000/animal/updateId/${editInfo.id}`;

    const [file,setFile] = useState(null);
	function editSubmit(e){
		e.preventDefault();
		const info = {
            userId: editInfo.userId,
            broughtFrom: editInfo.broughtFrom,
            name: editInfo.name,
            breed: editInfo.breed,
            color: editInfo.color,
            sex: editInfo.sex,
            price: editInfo.price,
            date: editInfo.date
          }
        if (typeof window !== 'undefined') {
          window.stopProceessing = true;
        };
        axios({
            method: 'post',
            url: edited,
            data: info,
            headers: {
                'Contet-Type': 'application/json',
            },
        })
        .then((response) =>{
            window.location ="/dashboard/my-scan";
        })
        .catch((response)=>{
            alert(response.response.data.error);
        });
    };

    function handleEdit(e){
        const newdata={...editInfo};
        newdata[e.target.name] = e.target.value;
        setEditInfo(newdata);
    };

    function handleFileSelect(event){
        setFile(event.target.files[0]);
        console.log(event.target.files[0]);
      };

    const imageDomain=process.env.REACT_APP_IMAGE_DOMAIN;
    return(              
        <>
            <Helmet>
                <title> Edit Tag | El Camino - Trucking </title>
            </Helmet>
            <Container>
                <div>
                    <p>Edit</p>
                    <form onSubmit={(e)=>editSubmit(e)}>
                    <div className='marginTen'>
                          <span>Bought from</span>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.broughtFrom} name="broughtFrom" placeholder="Bought from" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Name/ID</span>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.name} name="name" placeholder="Name/ID" type="text"  className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Breed</span>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.breed} name="breed" placeholder="Breed" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Color</span>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.color} name="color" placeholder="Color" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Sex</span>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.sex} name="sex" placeholder="Sex" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Price</span>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.price} name="price" placeholder="Price" type="number" className='formInput'/>
                        </div>
                        
                        <input type="submit" value="Edit tag" className='submitBtn'/>
                    </form>
                </div>
            </Container>
        </>
    )
}


