import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import {useEffect} from 'react';
import useResponsive from '../hooks/useResponsive';
// components

// sections
import LogoImg from '../assets/img/logo.svg';
import IOS from '../assets/img/download-appstore.svg'
import Android from '../assets/img/downlaod-googlestore.png'
import headerLogo from '../assets/img/bk.png'
import envelope from '../assets/img/envelope.png'

// CSS-------------------------------------------------------------------
import '../theme/homepage.css'
// ----------------------------------------------------------------------

function Homepage() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <div style={{background: 'white'}}>
      <div className="header-wrapper">
        <img src={headerLogo} alt="Header" width="100%" style={{objectFit:"cover"}} />
        <div className='header-menu'>
          <div>
            <img src={LogoImg} alt="Logo" style={{height: '70px'}}/>
          </div>
          <div>
            <h2 style={{color: '#FFF', height: '60px', letterSpacing: '0px', fontSize: '50px'}}>Welcome to AG ID</h2>
            <div className='flexHeader'>
              <div className="adminBox">
                <a href='https://roosterscow.com/login' style={{textAlign: 'center',letterSpacing:'0px',color:'#FFBE40', textDecoration:'none'}}>Admin Dashboard</a>
              </div>
              <div className="policyBox">
                <a href='https://roosterscow.com/privacy' style={{textAlign: 'center',letterSpacing:'0px',color:'#fff', textDecoration:'none'}}>Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className='container-info'>
          <h2 style={{color: '#FFBE40', height: '60px', letterSpacing: '0px', fontSize: '30px', marginBottom: '50px'}}>We are here to help you</h2>
          <p style={{marginBottom: '30px'}}>If you have any questions, please send us an email at</p>
          <div className="mailBox">
            <p style={{color: '#FFF'}}>support@roosterscow.com</p>
          </div>
          <p>or complete the Contact form below and send your message to us. <br/>We’ll answer as soon as possible.</p>
          <p style={{color: '#FFBE40', height: '60px', letterSpacing: '0px', fontSize: '20px', margin: '50px 0 20px'}}>Contact form</p>
          <div className="form">
            <div className='leftGrid'>
              <div>
                <p>First name*</p>
                <input type='text' name='firstname' />
              </div>
              <div>
                <p>Last name*</p>
                <input type='text' name='lastname' />
              </div>
              <div>
                <p>Email address*</p>
                <input type='text' name='email' />
              </div>
              <div>
                <p>Phone*</p>
                <input type='text' name='phone' />
              </div>
            </div>
            <div className='rightGrid'>
              <div>
                <p>Issue Details*</p>
                <textarea />
                <p style={{color: 'black', letterSpacing: '0px', fontSize: '15px', marginBottom: '52px'}}><span style={{color: '#FFBE40'}}>*</span> Mandatory fields</p>
              </div>
              <div className="submitBtnForm">
                <a href='#' style={{textAlign: 'center',letterSpacing:'0px',color:'#fff', textDecoration:'none'}}>Submit message</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="footerFlex">
          <div>
            <h3 style={{color: '#FFBE40', height: '60px', letterSpacing: '0px', fontSize: '30px', marginBottom: '40px'}}>Contact details</h3>
            <div className='footerFlexMail'>
              <img src={envelope} alt="mail" style={{objectFit:"contain"}} height={25}/>
              <p>info@roosterscow.com</p>
            </div>
          </div>
          <div>
            <div>
              <h3 style={{color: '#FFBE40', height: '60px', letterSpacing: '0px', fontSize: '30px', marginBottom: '10px'}}>Download our app from</h3>
            </div>
            <div style={{display: 'flex',alignItems: 'center',marginLeft: "-13px"}}>
              <a href="#">
                <img src={Android} alt="logo" width="250px"/>
              </a>
              <a href="#">
                <img src={IOS} alt="logo" width="200px"/>
              </a>
            </div>
          </div>
        </div>
        <hr />
        <p style={{textAlign: 'center', fontSize:"22px"}}>© 2024 AG ID App. All rights reserved</p>
      </footer>
    </div>
  );
}

export default Homepage